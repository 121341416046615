import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../../generic-page.scss';

const CSPVPConceptPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Intro to RTA concepts</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_pvphistory.png"
            alt="Intro to RTA concepts"
          />
        </div>
        <div className="page-details">
          <h1>Intro to RTA concepts</h1>
          <h2>Introduction to Basic RTA Concepts in Counter Side.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The purpose of this guide is to introduce new RTA players to common
          PVP concepts and detail the typical roles that form key team building
          considerations when assembling PVP team comps.
        </p>
        <p>
          There is a wealth of game knowledge hidden within the Counterside PVP
          gamemode that gives the game incredible replayability. With every
          weekly reset and a fresh set of bans, RTA opens itself up to exciting
          experimentation to uncover the new “meta”. This process can involve:
        </p>
        <ul>
          <li>
            Identifying and understanding the value of important RTA-oriented
            units
          </li>
          <li>Understanding the relevant unit interactions on a given week</li>
          <li>Researching your opponents’ comps and revising your own comp </li>
        </ul>
        <p>
          It can be difficult figuring out if what you’re doing is actually
          working. Between execution error, your opponent actively trying to
          undermine your efforts, and resource gaps masking otherwise bad
          decisions, there are numerous factors that can obfuscate more detailed
          analysis.{' '}
        </p>
        <p>
          As a big fan of Counterside’s unique PVP experience, I am writing this
          guide to help new players overcome that initial hurdle and segue them
          into the gamemode.
        </p>
        <p>
          If you have any questions or feedback, please don't be afraid to dm
          Yiggins who is the author of the guide <strong>Yiggles#8990</strong>.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="Intro to RTA concepts" />
        <p>
          Due to the guide being massive, very detailed and also to allow the
          author to easily edit it, it will be kept in the original form as a
          google doc.
        </p>
        <p>Link to the guide:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/document/d/1uLQpZBZqogGAQpLxGWu4hwxdjKUwniCdfUr32AOWJlk/edit#"
          target="_blank"
          rel="noreferrer"
        >
          Intro to RTA concepts
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default CSPVPConceptPage;

export const Head: React.FC = () => (
  <Seo
    title="Intro to RTA concepts | Counter Side | Prydwen Institute"
    description="Introduction to Basic RTA Concepts in Counter Side."
  />
);
